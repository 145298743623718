import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('../views/layout'),
    redirect:'/home',
    children:[
      {
        path: '/home',
        name: '首页',
        component: () => import('../views/home/index')
      },
      {
        path: '/bigType',
        name: '商品大类',
        component: () => import('../views/bigType/index')
      },
      {
        path: '/smallType',
        name: '商品小类',
        component: () => import('../views/smallType/index')
      },
      {
        path: '/product',
        name: '商品管理',
        component: () => import('../views/product/index')
      },
      {
        path: '/product1',
        name: '商品管理',
        component: () => import('../views/product1/index')
      },
      {
        path: '/dataShow',
        name: '仪表盘',
        component: () => import('../views/dataShow/index')
      },
      {
        path: '/printTest',
        name: '打印测试',
        component: () => import('../views/printTest/index')
      },
      {
        path: '/user',
        name: '用户管理',
        component: () => import('../views/user/index')
      },
      {
        path: '/productType',
        name: '商品类别管理',
        component: () => import('../views/productType/index')
      },
      {
        path: '/order',
        name: '订单管理',
        component: () => import('../views/order/index')
      },
      {
        path: '/modifyPassword',
        name: '修改密码',
        component: () => import('../views/modifyPassword/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',

    component: () => import('../views/login')
  },
  {
    path:'/erp',
    name: 'erp首页',
    component: () => import('../views/erp/layout'),
    redirect:'/erp/home',
    children: [
      {
        path: '/erp/home',
        name: 'erp首页',
        component: () => import('../views/erp/home/index')
      },
      {
        path: '/erp/order',
        name: '销售单管理',
        component: () => import('../views/erp/order/index')
      },
      {
        path: '/erp/customer',
        name: '客户管理',
        component: () => import('../views/erp/customer/index')
      },
      {
        path: '/erp/supplier',
        name: '供应商管理',
        component: () => import('../views/erp/supplier/index')
      },
      {
        path: '/erp/product',
        name: '产品',
        component: () => import('../views/erp/product/index')
      },
      {
        path: '/erp/color',
        name: '颜色',
        component: () => import('../views/erp/color/index')
      },
      {
        path: '/erp/gathering',
        name: '客户收款',
        component: () => import('../views/erp/gathering/index')
      },
      {
        path: '/erp/dataShow',
        name: '仪表',
        component: () => import('../views/erp/dataShow/index')
      },
      {
        path: '/erp/customerStatement',
        name: '对账单',
        component: () => import('../views/erp/customerStatement/index')
      },
    ]
  },
  {
    path:'/factory',
    name: 'factory首页',
    component: () => import('../views/factory/layout'),
    redirect:'/factory/home',
    children: [
      {
        path: '/factory/home',
        name: 'factory首页',
        component: () => import('../views/factory/home/index')
      },
      {
        path: '/factory/order',
        name: 'factory销售单管理',
        component: () => import('../views/factory/order/index')
      },
      {
        path: '/factory/customer',
        name: 'factory客户管理',
        component: () => import('../views/factory/customer/index')
      },
      {
        path: '/factory/supplier',
        name: 'factory供应商管理',
        component: () => import('../views/factory/supplier/index')
      },
      {
        path: '/factory/product',
        name: 'factory产品',
        component: () => import('../views/factory/product/index')
      },
      {
        path: '/factory/color',
        name: 'factory颜色',
        component: () => import('../views/factory/color/index')
      },
      {
        path: '/factory/gathering',
        name: 'factory客户收款',
        component: () => import('../views/factory/gathering/index')
      },
      {
        path: '/factory/dataShow',
        name: 'factory仪表',
        component: () => import('../views/factory/dataShow/index')
      },
      {
        path: '/factory/customerStatement',
        name: 'factory对账单',
        component: () => import('../views/factory/customerStatement/index')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
