import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import '././assets/styles/border.css'
import '././assets/styles/reset.css'
import SvgIcon from '@/icons'
import '@/router/permission.js'
import print from 'vue3-print-nb'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
createApp(App).use(ElementPlus, {
    locale: zhCn,
}).use(store).use(router).use(ElementPlus).use(print).use(SvgIcon).mount('#app')
